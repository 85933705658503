import React, { Component } from 'react'

export default class SearchArticle extends Component {
    render() {
        return (
            <div className="evk-searchbox-article">
                <a href={this.props.article.meta.html_url}>
                    <div
                        className="evk-searchbox-article-inner"
                        style={{
                            'background-image': `url('${this.props.article.image_search_url}')`,
                        }}
                    ></div>
                </a>
            </div>
        )
    }
}
