import React, { Component } from 'react'
import PubSub from 'pubsub-js'

import SearchArticle from './SearchArticle'

export default class Search extends Component {
    constructor() {
        super()
        this.state = { hidden: true, searchboxHidden: true, articles: [] }
    }
    componentDidMount() {
        PubSub.subscribe('SEARCH', val => {
            if (this.state.hidden) {
                fetch(
                    `https://www.efo-magazin.de/api/v2/pages/?type=magazine.MagazineItem&order=random&fields=image_search_url`
                )
                    .then(res => res.json())
                    .then(json => {
                        this.parseArticles(json)
                    })
                this.setState({ hidden: false }, () =>
                    setTimeout(() => {
                        this.setState({ searchboxHidden: false })
                        this.searchInput.focus()
                    }, 500)
                )
            } else {
                this.setState({
                    hidden: true,
                    searchboxHidden: true,
                    articles: [],
                })
            }
        })
    }
    parseArticles = json => {
        this.setState({ articles: json.items.slice(0, 9) })
    }
    render() {
        let classes = 'evk-searchbox'
        let searchboxClasses = 'evk-searchbox-content'
        if (this.state.hidden) {
            classes += ' hidden'
        }
        if (this.state.searchboxHidden) {
            searchboxClasses += ' hidden'
        }
        let articles = this.state.articles.map(article => <SearchArticle article={article} />)
        return (
            <div className={classes}>
                {articles}
                <div className="evk-searchbox-overlay">
                    <form
                        className={searchboxClasses}
                        type="GET"
                        action={`https://www.efo-magazin.de/suche/`}
                    >
                        <div className="form-inline">
                            <label htmlFor="search-query" className="evk-searchbox-label">
                                Nach Artikeln, Terminen, Angeboten suchen:
                            </label>
                            <span
                                className="evk-searchbox-close"
                                onClick={() => {
                                    this.setState({
                                        hidden: true,
                                        searchboxHidden: true,
                                        articles: [],
                                    })
                                }}
                            >
                                <i className="fa fa-times" />
                                Schließen
                            </span>
                        </div>
                        <div className="form-inline">
                            <input
                                ref={input => {
                                    this.searchInput = input
                                }}
                                className="form-control evk-searchbox-input"
                                id="search-query"
                                name="query"
                                placeholder="Suche"
                            />
                            <button type="submit" className="btn btn-info evk-searchbox-submit">
                                Suchen
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
