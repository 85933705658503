import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import '@fontsource/open-sans'

import styleSelect from 'styleselect'
import 'multiple-select'
import PubSub from 'pubsub-js'
import '../node_modules/sticky-kit/dist/sticky-kit.min.js'
import baguetteBox from 'baguettebox.js'

import { googleMapsKey } from './settings'
import './screen.css'
import getParameterByName from './util/getparameterbyname'
import getAllParameters from './util/getallparameters'

import Search from './Search'
import CongregationFinder from './CongregationFinder'
import Gallery from './Gallery'

import location from './images/location.png'

// define subcomponent mountpoints here
ReactDOM.render(<Search />, document.getElementById('evk-search'))

if (document.getElementById('evk-congregationfinder') !== null) {
    ReactDOM.render(<CongregationFinder />, document.getElementById('evk-congregationfinder'))
}

window.$(document).ready($ => {
    const simpleGalleries = document.getElementsByClassName('evk-gallery simple')
    if (simpleGalleries.length > 0) {
        baguetteBox.run('.evk-gallery.simple', {
            captions: function (e) {
                return e.getElementsByTagName('img')[0].title
            },
        })
    }

    const downloadGalleries = document.getElementsByClassName('evk-gallery downloadable')
    if (downloadGalleries.length > 0) {
        PubSub.subscribe('GALLERY', (msg, data) => {
            if (data.action && data.action === 'init') {
                const dlIcon =
                    '<button type="button" id="download-button" aria-label="Download" class="baguetteBox-button"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"><path fill="none" stroke="rgb(160,160,160)" stroke-width="3" d="M26.25 17.0317v8H3.75v-8m15.625-2.9693h0L15 18.4374l-4.375-4.375M15 18.4374V4.957"/></svg></button>'
                let added = false

                baguetteBox.run('.evk-gallery.downloadable .pictures', {
                    captions: function (e) {
                        return e.getElementsByTagName('img')[0].title
                    },
                    onChange: function (currentIndex) {
                        if (!added) {
                            $('button#close-button').before(dlIcon)
                            added = true
                        }
                        $('button#download-button')
                            .unbind()
                            .on('click', () => {
                                PubSub.publish('GALLERY', {
                                    action: 'download',
                                    index: currentIndex,
                                })
                            })
                    },
                })
            }

            if (data.action && data.action === 'hideOverlay') {
                baguetteBox.hide('.evk-gallery.downloadable')
            }
        })

        downloadGalleries.forEach(gallery => {
            let ids = gallery.dataset.ids
            let thumbs = gallery.dataset.thumbs
            let images = gallery.dataset.images
            let titles = gallery.dataset.titles
            ReactDOM.render(
                <Gallery ids={ids} thumbs={thumbs} images={images} titles={titles} />,
                gallery
            )
        })
    }

    $('.evk-search-toggle').on('click', () => {
        PubSub.publish('SEARCH', 'toggle')
    })

    $('#evk-congregationfinder-select-ffm').on('change', e => {
        PubSub.publish('FINDER_SORT_FFM', e.target.value)
    })
    $('#evk-congregationfinder-select-of').on('change', e => {
        PubSub.publish('FINDER_SORT_OF', e.target.value)
    })

    // Close open Search with ESC
    $(document).keyup(e => {
        if (e.keyCode === 27 && !$('div.evk-searchbox').hasClass('hidden')) {
            PubSub.publish('SEARCH', 'toggle')
        }
    })

    // add 'submitted' class to activate visual validation
    $(document).on('click', 'form button[type=submit]', function (e) {
        $(e.target).parents('form').addClass('submitted')
    })

    // Styling dropdowns
    if ($('select.evk-select').length) {
        $('select.evk-select').each((i, e) => {
            styleSelect('#' + e.id)
        })
    }
    if ($('select.evk-select-multiple').length) {
        $('select.evk-select-multiple').each((i, e) => {
            let element = $('#' + e.id),
                placeholder = element.data('placeholder'),
                type = element.data('type'),
                changed = false,
                changeBtn = $(
                    '<li class="ms-changeBtn"><label><button class="btn btn-outline-info">Übernehmen</button></label></li>'
                )

            let countSelected
            if (type === 'categories') {
                countSelected = (count, total) => `${count} von ${total} Kategorien`
            } else if (type === 'audiences') {
                countSelected = (count, total) => `${count} von ${total} Zielgruppen`
            } else if (type === 'seminar-audience') {
                countSelected = (count, total) => `${count} von ${total} Zielgruppen`
            } else if (type === 'seminar-month') {
                countSelected = (count, total) => `${count} von ${total} Monaten`
            } else if (type === 'seminar-location') {
                countSelected = (count, total) => `${count} von ${total} Orten`
            } else if (type === 'seminar-category') {
                countSelected = (count, total) => `${count} von ${total} Kategorien`
            }

            $('#' + e.id).multipleSelect({
                placeholder: placeholder,
                allSelected: placeholder,
                selectAll: false,
                minimumCountSelected: 1,
                countSelected: countSelected,
                formatAllSelected: () => 'Alle',
                formatCountSelected: countSelected,
                maxHeight: 400,
                onAfterCreate: () => {
                    let ul = element.next('.ms-parent.evk-select-multiple').find('.ms-drop ul')
                    ul.prepend(changeBtn)
                    changeBtn.on('click', () => {
                        $('#' + e.id).multipleSelect('close')
                    })
                },
                textTemplate: $el => {
                    const logoUrl = $el.data('logo-url')
                    if (logoUrl) {
                        return `${$el.html()} <img src="${logoUrl}">`
                    } else {
                        return $el.html()
                    }
                },
                onClose: () => {
                    if (changed) {
                        changed = false
                        const curUrl = new URL(window.location)
                        let values = $('#' + e.id).multipleSelect('getSelects')
                        let url

                        const isSeminar = type.startsWith('seminar-')
                        if (isSeminar) {
                            url = `./?f=1`
                            if (type === 'seminar-category') {
                                for (let audience of values) {
                                    url += `&audience=${audience}`
                                }
                            }
                            if (type === 'seminar-audience') {
                                let months = curUrl.searchParams.getAll('month')
                                let locations = curUrl.searchParams.getAll('location')
                                for (let audience of values) {
                                    url += `&audience=${audience}`
                                }
                                for (let month of months) {
                                    url += `&month=${month}`
                                }
                                for (let location of locations) {
                                    url += `&location=${location}`
                                }
                            }
                            if (type === 'seminar-month') {
                                let audiences = curUrl.searchParams.getAll('audience')
                                let locations = curUrl.searchParams.getAll('location')
                                for (let audience of audiences) {
                                    url += `&audience=${audience}`
                                }
                                for (let month of values) {
                                    url += `&month=${month}`
                                }
                                for (let location of locations) {
                                    url += `&location=${location}`
                                }
                            }
                            if (type === 'seminar-location') {
                                let audiences = curUrl.searchParams.getAll('audience')
                                let months = curUrl.searchParams.getAll('month')
                                for (let audience of audiences) {
                                    url += `&audience=${audience}`
                                }
                                for (let month of months) {
                                    url += `&month=${month}`
                                }
                                for (let location of values) {
                                    url += `&location=${location}`
                                }
                            }
                        } else {
                            let now = new Date()
                            let year = getParameterByName('year') || now.getFullYear()
                            let month = getParameterByName('month') || now.getMonth() + 1
                            let day = getParameterByName('day') || now.getDate()

                            url = `./?year=${year}&month=${month}&day=${day}`
                            if (type === 'categories') {
                                let audiences = curUrl.searchParams.getAll('audience')
                                for (let audience of audiences) {
                                    url += `&audience=${audience}`
                                }
                                for (let category of values) {
                                    url += `&category=${category}`
                                }
                            } else if (type === 'audiences') {
                                let categories = curUrl.searchParams.getAll('category')
                                for (let category of categories) {
                                    url += `&category=${category}`
                                }
                                for (let audience of values) {
                                    url += `&audience=${audience}`
                                }
                            }
                        }

                        window.location.href = url
                    }
                },
                onClick: () => {
                    changed = true
                },
            })
        })
    }

    // Navigation hover
    $('nav.evk-subnav ul li').each((i, e) => {
        let nav = $(e)
        let color = nav.find('a').data('color')
        let restoreAll = typeof nav.data('restore-effect') === 'undefined' ? false : true

        nav.hover(
            () => {
                nav.siblings().not('.active').find('a').css('border-color', 'transparent')
                nav.find('a').css('border-color', color)
            },
            () => {
                if (restoreAll) {
                    nav.siblings()
                        .find('a')
                        .map(
                            (index, element) => (element.style.borderColor = element.dataset.color)
                        )
                } else {
                    nav.not('.active').find('a').css('border-color', 'transparent')
                }
            }
        )
    })

    // Panel hover
    $('.evk-panel-inner').each((i, e) => {
        let panel = $(e)

        // eslint-disable-next-line
        let change = (c, n) => {
            return c.map(function (d) {
                return (d += n) < 0 ? 0 : d > 255 ? 255 : d | 0
            })
        }

        let oldColor = panel.css('background-color')
        oldColor = oldColor.replace('rgb(', '').replace(')', '').split(',').map(Number)

        let newColor = change(oldColor, -15)

        panel.hover(
            () => {
                panel.css('background-color', `rgb(${newColor[0]}, ${newColor[1]}, ${newColor[2]})`)
            },
            () => {
                panel.css('background-color', `rgb(${oldColor[0]}, ${oldColor[1]}, ${oldColor[2]})`)
            }
        )
    })

    // Mobile nav
    $('#evk-mobile-nav')
        .on('show.bs.collapse', () => {
            $('#evk-mobile-search').collapse('hide')
            $('.evk-header-row > .col-2').first().addClass('active-column')
            $('a.evk-menu-toggle').find('i').removeClass('fa-bars').addClass('fa-times')
        })
        .on('hide.bs.collapse', () => {
            $('.evk-header-row > .col-2').first().removeClass('active-column')
            $('a.evk-menu-toggle').find('i').removeClass('fa-times').addClass('fa-bars')
        })

    // Mobile search
    $('#evk-mobile-search')
        .on('show.bs.collapse', () => {
            $('#evk-mobile-nav').collapse('hide')
            $('.evk-header-row > .col-2').last().addClass('active-column')
            $('a.evk-search-toggle').find('i').removeClass('fa-search').addClass('fa-times')
        })
        .on('hide.bs.collapse', () => {
            $('.evk-header-row > .col-2').last().removeClass('active-column')
            $('a.evk-search-toggle').find('i').removeClass('fa-times').addClass('fa-search')
        })
        .on('shown.bs.collapse', () => {
            $('#evk-mobile-search').find('input').focus()
        })

    // Article anchor smooth scroll
    $("a[href='#comments']").click(e => {
        e.preventDefault()
        $('html, body').animate(
            {
                scrollTop: $('#comments').offset().top,
            },
            500
        )
    })

    // Sidebar sticky
    $('aside').stick_in_parent()

    // Normal accordions
    $('.page-collapse')
        .collapse('hide', {
            // parent: 'div.accordion'
        })
        .on('show.bs.collapse', event => {
            $(event.target).prev('div.card-header').addClass('active')
        })
        .on('hide.bs.collapse', event => {
            $(event.target).prev('div.card-header').removeClass('active')
        })

    // Mobile side block accordions
    $('.mobile-accordion')
        .prevAll('h5')
        .click(e => {
            let $this = $(e.currentTarget),
                speed = 300

            let open = content => {
                let handler = content.prevAll('h5')
                handler.find('i.fa').removeClass('fa-angle-down').addClass('fa-angle-up')
                content
                    .removeClass('hidden-md-down')
                    .addClass('accordion-open')
                    .hide()
                    .slideToggle(speed)
            }

            let close = content => {
                let handler = content.prevAll('h5')
                handler.find('i.fa').removeClass('fa-angle-up').addClass('fa-angle-down')
                content.slideToggle(speed, () => {
                    content.removeClass('accordion-open').addClass('hidden-md-down').show()
                })
            }

            let closeAll = () => {
                $('.accordion-open').each((i, e) => {
                    close($(e))
                })
            }

            let content = $this.nextAll('div.mobile-accordion')
            if ($this.find('i.fa').is(':visible')) {
                if (content.hasClass('hidden-md-down')) {
                    closeAll()
                    open(content)
                } else {
                    close(content)
                }
            }
        })

    // Datepicker for custom forms
    if (typeof $().datetimepicker === 'function') {
        $('.form-control.datetime').datetimepicker({
            format: 'd.m.Y H:i',
        })
        $('.form-control.date').datetimepicker({
            timepicker: false,
            format: 'd.m.Y',
        })
    }

    let filterForm = $('form#evk-search-filter')
    if (filterForm !== null) {
        filterForm.find("input[type='checkbox']").on('change', e => {
            let query = getParameterByName('query')
            let filters = getAllParameters()['filter'] || []
            let new_filter = e.target.value

            if ($.inArray(new_filter, filters) !== -1) {
                filters.splice(filters.indexOf(new_filter), 1)
            } else {
                filters.push(new_filter)
            }

            let query_string = $.param(
                {
                    query: query,
                    filter: filters,
                },
                true
            )
            window.location.href = `./?${query_string}`
        })
    }

    // Video as article images functionality
    let videoContainers = Array.from(document.querySelectorAll('div.video-container'))
    videoContainers.forEach(videoContainer => {
        let video = videoContainer.querySelector('video'),
            btnPlay = videoContainer.querySelector('svg.btn-play'),
            btnUnmute = videoContainer.querySelector('svg.btn-unmute'),
            btnMute = videoContainer.querySelector('svg.btn-mute')

        const startVideo = event => {
            const video = event.target

            video.removeEventListener('click', startVideo)
            video.setAttribute('controls', 'controls')

            window.setTimeout(() => video.play(), 100)
        }

        if (videoContainer && video) {
            if (btnUnmute && btnMute) {
                btnUnmute.addEventListener('click', () => {
                    video.muted = false
                    btnMute.classList.remove('inactive')
                    btnUnmute.classList.add('inactive')
                })
                btnMute.addEventListener('click', () => {
                    video.muted = true
                    btnMute.classList.add('inactive')
                    btnUnmute.classList.remove('inactive')
                })
            }
            if (btnPlay) {
                video.addEventListener('click', startVideo, { once: true })
            }
            video.addEventListener('playing', () => {
                video.classList.remove('inactive')
                if (btnPlay) {
                    btnPlay.style.display = 'none'
                }
            })
        }
    })
})
