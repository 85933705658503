import React, { Component } from 'react'
import PubSub from 'pubsub-js'

let API_URL
if (window.location.origin === 'https://www.efo-magazin.de') {
    API_URL = 'https://www.efo-magazin.de'
} else if (window.location.origin === 'https://efo.dev.candylabs.net') {
    API_URL = 'https://efo.dev.candylabs.net'
} else {
    API_URL = 'http://localhost:8000'
}

export default class CongregationFinder extends Component {
    constructor() {
        super()
        this.state = {
            congregations: [],
            street: '',
            streetId: 0,
            number: '',
            city: 'FFM',
            suggestions: [],
            sameCitySuggestions: true,
            sortByFFM: 'district',
            sortByOF: 'district',
            missingFieldsError: false,
        }

        fetch(`${API_URL}/api/v2/congregations/?limit=1000`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    congregationsTotalFFM: json.items.filter(c => c.city === 'FFM'),
                    congregationsTotalOF: json.items.filter(c => c.city === 'OF'),
                })
            })
    }

    componentDidMount() {
        PubSub.subscribe('FINDER_SORT_FFM', (e, sortByFFM) => {
            this.setState({ sortByFFM })
        })
        PubSub.subscribe('FINDER_SORT_OF', (e, sortByOF) => {
            this.setState({ sortByOF })
        })
    }

    search = () => {
        if (this.state.street.length === 0 || this.state.number.length === 0) {
            this.setState({ missingFieldsError: true })
            return
        }

        this.setState({ missingFieldsError: false })

        fetch(
            `${API_URL}/api/v2/congregationfinder/?street=${encodeURIComponent(
                this.state.street
            )}&number=${encodeURIComponent(this.state.number)}&street_id=${encodeURIComponent(
                this.state.streetId
            )}`
        )
            .then(res => res.json())
            .then(json => {
                let suggestions = []
                if (typeof json.congregations === 'undefined') {
                    if (typeof json.suggestions !== 'undefined') {
                        suggestions = json.suggestions.slice(0, 9)
                    }
                }

                const sameCitySuggestions = suggestions.every(s => {
                    return s.city === suggestions[0].city
                })

                const congregations = []
                if (json.congregations) {
                    json.congregations.forEach(e => {
                        if (congregations.find(c => c.name === e.name)) {
                            return
                        }
                        congregations.push(e)
                    })
                }

                this.setState({
                    congregations,
                    suggestions,
                    sameCitySuggestions,
                })
            })
    }

    sortingIndicatorFFM = congregation => {
        if (this.state.sortByFFM === 'name') {
            let n = congregation.name
            n = n.replace('Kirchengemeinde ', '')
            n = n.replace('Frankfurter ', '')
            return n.slice(0, 1).toUpperCase()
        }
        if (this.state.sortByFFM === 'district') {
            if (congregation.district.length) {
                return congregation.district
            }
            return '-'
        }
        return ''
    }
    sortingIndicatorOF = congregation => {
        if (this.state.sortByOF === 'name') {
            let n = congregation.name
            n = n.replace('Kirchengemeinde ', '')
            n = n.replace('Frankfurter ', '')
            return n.slice(0, 1).toUpperCase()
        }
        if (this.state.sortByOF === 'district') {
            if (congregation.district.length) {
                return congregation.district
            }
            return '-'
        }
        return ''
    }

    fixEvents = () => {}

    render() {
        let result
        if (typeof this.state.congregations === 'undefined') {
            if (this.state.suggestions.length > 0) {
                if (this.state.sameCitySuggestions) {
                    result = <p>Versuchen Sie es doch mit den folgenden Vorschl&auml;gen:</p>
                } else {
                    result = (
                        <p>
                            Die eingegebene Adresse gibt es in Frankfurt und Offenbach. Bitte
                            w&auml;hlen Sie eine der Optionen aus:
                        </p>
                    )
                }
            } else {
                result = (
                    <p>Leider haben wir für Ihre Stra&szlig;e keine passende Gemeinde gefunden.</p>
                )
            }
        }
        if (this.state.missingFieldsError) {
            result = <p>Bitte füllen Sie beide Felder aus, um die Gemeindesuche zu bedienen.</p>
        }

        let congregationsTotalFFM = []
        if (typeof this.state.congregationsTotalFFM !== 'undefined') {
            let congregationLists = {}
            this.state.congregationsTotalFFM.forEach(c => {
                let si = this.sortingIndicatorFFM(c)
                if (Array.isArray(si)) {
                    for (let district of si) {
                        if (typeof congregationLists[district.value] === 'undefined') {
                            congregationLists[district.value] = []
                        }
                    }
                } else {
                    if (typeof congregationLists[si] === 'undefined') {
                        congregationLists[si] = []
                    }
                }
                if (Array.isArray(si)) {
                    for (let district of si) {
                        congregationLists[district.value].push(
                            <li key={c.id}>
                                <a href={`../kirchengemeinde/${c.url}`}>{c.name}</a>
                            </li>
                        )
                    }
                } else {
                    congregationLists[si].push(
                        <li key={c.id}>
                            <a href={`../kirchengemeinde/${c.url}`}>{c.name}</a>
                        </li>
                    )
                }
            })

            let keys = Object.keys(congregationLists)
            for (let key of keys.sort((a, b) => a.localeCompare(b))) {
                congregationsTotalFFM.push(
                    <div key={key}>
                        <h4>{key}</h4>
                        <ul>{congregationLists[key]}</ul>
                    </div>
                )
            }
            congregationsTotalFFM = (
                <div className="evk-congregation-sortedlist">{congregationsTotalFFM}</div>
            )
        }

        let congregationsTotalOF = []
        if (typeof this.state.congregationsTotalOF !== 'undefined') {
            let congregationLists = {}
            this.state.congregationsTotalOF.forEach(c => {
                let si = this.sortingIndicatorOF(c)
                if (Array.isArray(si)) {
                    for (let district of si) {
                        if (typeof congregationLists[district.value] === 'undefined') {
                            congregationLists[district.value] = []
                        }
                    }
                } else {
                    if (typeof congregationLists[si] === 'undefined') {
                        congregationLists[si] = []
                    }
                }
                if (Array.isArray(si)) {
                    for (let district of si) {
                        congregationLists[district.value].push(
                            <li key={c.id}>
                                <a href={`../kirchengemeinde/${c.url}`}>{c.name}</a>
                            </li>
                        )
                    }
                } else {
                    congregationLists[si].push(
                        <li key={c.id}>
                            <a href={`../kirchengemeinde/${c.url}`}>{c.name}</a>
                        </li>
                    )
                }
            })

            let keys = Object.keys(congregationLists)
            for (let key of keys.sort((a, b) => a.localeCompare(b))) {
                congregationsTotalOF.push(
                    <div key={key}>
                        <h4>{key}</h4>
                        <ul>{congregationLists[key]}</ul>
                    </div>
                )
            }
            congregationsTotalOF = (
                <div className="evk-congregation-sortedlist">{congregationsTotalOF}</div>
            )
        }

        let suggestions
        if (this.state.suggestions.length > 0) {
            suggestions = this.state.suggestions.map(s => (
                <li
                    key={s.id}
                    onClick={() => {
                        this.refs.street.value = s.name
                        this.setState({ street: s.name, streetId: s.id }, this.search)
                    }}
                >
                    {s.city} - {s.name} {this.state.number}
                </li>
            ))
            suggestions = <ul className="evk-congregationfinder-suggestions">{suggestions}</ul>
        }

        setTimeout(this.fixEvents, 10)

        return (
            <div>
                <div className="nav-tabs-wrapper">
                    <ul className="container nav nav-tabs" role="tablist">
                        <li
                            className="nav-item"
                            onClick={() => {
                                document.querySelector('.nav-tabs-wrapper').scrollLeft = 0
                            }}
                        >
                            <a
                                className="nav-link active"
                                data-toggle="tab"
                                href="#evk-congregationfinder-bymap"
                                role="tab"
                            >
                                Gemeinde&shy;suche
                            </a>
                        </li>
                        <li
                            className="nav-item"
                            onClick={() => {
                                document.querySelector('.nav-tabs-wrapper').scrollLeft = 50
                            }}
                        >
                            <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#evk-congregationfinder-bylist-ffm"
                                role="tab"
                            >
                                Alle Frankfurter Gemeinden
                            </a>
                        </li>
                        <li
                            className="nav-item"
                            onClick={() => {
                                document.querySelector('.nav-tabs-wrapper').scrollLeft = 100
                            }}
                        >
                            <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#evk-congregationfinder-bylist-of"
                                role="tab"
                            >
                                Alle Offenbacher Gemeinden
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="evk-congregationfinder-content">
                    <div
                        className="tab-pane active"
                        id="evk-congregationfinder-bymap"
                        role="tabpanel"
                    >
                        <form className="container" onSubmit={event => event.preventDefault()}>
                            <p>
                                Bitte geben Sie hier Ihre <strong>Straße</strong> und{' '}
                                <strong>Hausnummer</strong> ein, um Ihre Gemeinde zu finden.
                            </p>
                            <div className="input-group">
                                <input
                                    ref="street"
                                    onChange={e =>
                                        this.setState({
                                            street: e.target.value,
                                        })
                                    }
                                    className="form-control"
                                    placeholder="z.B. Hanauer Landstraße"
                                />
                                <input
                                    onChange={e =>
                                        this.setState({
                                            number: e.target.value,
                                        })
                                    }
                                    className="form-control"
                                    placeholder="z.B. 20"
                                />
                                <button
                                    className="btn btn-outline-info"
                                    onClick={() => {
                                        this.setState({ streetId: 0 }, this.search)
                                    }}
                                >
                                    Suchen
                                </button>
                            </div>
                            <div className="evk-congregationfinder-result">{result}</div>
                            {suggestions}
                        </form>

                        {this.state.congregations && (
                            <div className="evk-event-list container">
                                <ul>
                                    {this.state.congregations.map((entry, index) => {
                                        return (
                                            <li key={index}>
                                                <a
                                                    className="evk-no-decoration"
                                                    href={`../kirchengemeinde/${entry.url}`}
                                                >
                                                    <div className="title">{entry.name}</div>
                                                    <div
                                                        className="teaser mb-1"
                                                        style={{ clear: 'both' }}
                                                    >
                                                        {entry.office_address}
                                                    </div>
                                                    <div className="category">
                                                        {entry.district[0]}
                                                    </div>
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div
                        className="tab-pane"
                        id="evk-congregationfinder-bylist-ffm"
                        role="tabpanel"
                    >
                        <div className="container">
                            <div className="sort-control">
                                <p>Finden Sie die Kontaktdaten aller Gemeinden in Frankfurt.</p>
                                <span>Sortieren</span>
                                <select
                                    className="evk-select"
                                    id="evk-congregationfinder-select-ffm"
                                >
                                    <option value="district">nach Stadtteil</option>
                                    <option value="name">von A-Z</option>
                                </select>
                            </div>
                            {congregationsTotalFFM}
                        </div>
                    </div>
                    <div className="tab-pane" id="evk-congregationfinder-bylist-of" role="tabpanel">
                        <div className="container">
                            <div className="sort-control">
                                <p>Finden Sie die Kontaktdaten aller Gemeinden in Offenbach.</p>
                                <span>Sortieren</span>
                                <select
                                    className="evk-select"
                                    id="evk-congregationfinder-select-of"
                                >
                                    <option value="district">nach Stadtteil</option>
                                    <option value="name">von A-Z</option>
                                </select>
                            </div>
                            {congregationsTotalOF}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
