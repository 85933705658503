export default (name, url) => {
    if (name !== '' && name !== null && name !== undefined) {
        name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
            results = regex.exec(window.location.search)
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
    } else {
        let arr = window.location.href.split('/')
        return arr[arr.length - 1]
    }
}
