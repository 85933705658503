import React, { Component } from 'react'
import PubSub from 'pubsub-js'

class Search extends Component {
    constructor(props) {
        super(props)

        const ids = this.props.ids.slice(0, -1).split(';')
        const thumbs = this.props.thumbs.slice(0, -1).split(';')
        const images = this.props.images.slice(0, -1).split(';')
        const titles = this.props.titles.slice(0, -1).split(';')

        this.state = {
            ids,
            thumbs,
            images,
            titles,
            acceptedTerms: false,
            downloading: false,
            selected: ids.reduce(
                (options, option) => ({
                    ...options,
                    [option]: false,
                }),
                {}
            ),
        }
    }

    componentDidMount() {
        PubSub.publish('GALLERY', { action: 'init' })
        PubSub.subscribe('GALLERY', (msg, data) => {
            if (data.action && data.action === 'download') {
                this.triggerSingleDownload(data.index)
            }
        })
    }

    componentWillUnmount() {
        PubSub.unsubscribe('GALLERY')
    }

    handleSelect = event => {
        const { name } = event.target

        this.setState(prevState => ({
            selected: {
                ...prevState.selected,
                [name]: !prevState.selected[name],
            },
        }))
    }

    getSelectedTitles = () => {
        let titles = []
        Object.keys(this.state.selected)
            .filter(i => this.state.selected[i])
            .forEach(i => {
                titles.push(this.state.titles[this.state.ids.indexOf(i)])
            })
        return titles
    }

    triggerSingleDownload = index => {
        const imageId = this.state.ids[index]
        const selected = this.state.selected
        selected[imageId] = true
        this.setState({ selected }, () => {
            PubSub.publish('GALLERY', {
                action: 'hideOverlay',
            })
            this.refs.triggerPopupButton.click()
        })
    }

    download = () => {
        if (this.state.downloading) return

        const selectedIds = Object.keys(this.state.selected).filter(i => this.state.selected[i])

        this.setState({ downloading: true })

        fetch('/download_gallery_images/', {
            method: 'post',
            body: JSON.stringify(selectedIds),
        })
            .then(res => res.blob())
            .then(blob => {
                const href = window.URL.createObjectURL(blob)
                const a = this.refs.linkRef
                a.href = href
                a.download = 'Bildmaterial.zip'
                a.click()
                this.setState({ downloading: false })
            })
    }

    render() {
        const numSelected = Object.keys(this.state.selected).filter(
            i => this.state.selected[i]
        ).length

        return (
            <div>
                <div className="evk-gallery">
                    <div className="heading">
                        {numSelected > 1 && <p>{`${numSelected} Bilder ausgewählt.`}</p>}
                        {numSelected === 1 && <p>{`${numSelected} Bild ausgewählt.`}</p>}
                        {numSelected === 0 && <p>Wählen Sie Bilder zum Download aus.</p>}
                        <button
                            type="button"
                            disabled={numSelected === 0}
                            ref="triggerPopupButton"
                            className="btn btn-outline-info btn-gallery-download"
                            data-toggle="modal"
                            data-target="#downloadModal"
                        >
                            Jetzt herunterladen
                        </button>
                    </div>
                    <div className={`pictures ${numSelected > 0 ? 'selectMode' : ''}`}>
                        {this.state.ids.map((id, i) => {
                            return (
                                <div className="entry" key={i}>
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name={id}
                                            checked={this.state.selected[id]}
                                            onChange={this.handleSelect}
                                        />
                                    </label>
                                    <a href={this.state.images[i]}>
                                        <img
                                            src={this.state.thumbs[i]}
                                            alt={this.state.titles[i]}
                                            title={this.state.titles[i]}
                                        />
                                    </a>
                                    <p>{this.state.titles[i]}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="downloadModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="downloadModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="downloadModalLabel">
                                    {numSelected > 1 ? 'Bilder' : 'Bild'} herunterladen
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="fa fa-close"></i>
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {numSelected > 1 && (
                                    <p>Wollen Sie folgende Bilder herunterladen?</p>
                                )}
                                {numSelected === 1 && (
                                    <p>Wollen Sie folgendes Bild herunterladen?</p>
                                )}
                                <ul>
                                    {this.getSelectedTitles().map((title, i) => (
                                        <li key={i}>{title}</li>
                                    ))}
                                </ul>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={this.state.acceptedTerms}
                                        onChange={() =>
                                            this.setState({
                                                acceptedTerms: !this.state.acceptedTerms,
                                            })
                                        }
                                    />
                                    Mit dem Download des Bildmaterials stimme ich den{' '}
                                    <a
                                        href="https://www.efo-magazin.de/kirche/presse/nutzungsbedingungen/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Nutzungsbedingungen
                                    </a>{' '}
                                    zu.
                                </label>
                                <button
                                    type="button"
                                    disabled={!this.state.acceptedTerms || this.state.downloading}
                                    className="btn btn-info"
                                    onClick={this.download}
                                >
                                    {this.state.downloading
                                        ? 'Download startet automatisch ...'
                                        : 'Jetzt herunterladen'}
                                </button>
                                <a ref="linkRef" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Search
