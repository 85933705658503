export default () => {
    let qd = {}
    if (window.location.search) {
        window.location.search
            .substr(1)
            .split('&')
            .forEach(item => {
                let [k, v] = item.split('=')
                v = v && decodeURIComponent(v)
                ;(qd[k] = qd[k] || []).push(v)
            })
    }
    return qd
}
